.alert-modal {
  display: flex;
  position: fixed; /* Stay in place */
  z-index: 1301;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.alert-modal-content {
  position: relative;
  background: #FFFFFF;
  width: 400px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alert-image {
  height: 72px;
  width: 72px;
  margin: 34px 0 18px;
  align-self: center;
  animation-delay: 1s;
  @media only screen and (max-width: 768px) {
    height: 64px;
    width: 64px;
  }
}

.alert-title {
  //width: 220px;
  color: #2F3542;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.alert-subtitle {

  color: #2F3542;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;

  text-transform: initial;
  font-weight: normal;
  padding: 8px 24px 0;
  @media only screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}

.divider {
  margin: 2px 80px 10px 80px;
  border-bottom: 1px solid #EEEEEE;
  @media only screen and (max-width: 480px) {
    margin: 15px 40px;
  }
}

.alert-description {
  color: #164974;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  text-transform: initial;
  font-weight: normal;
  padding: 2px 40px 8px;
}

//.cancel-button {
//  background-color: #DE2525;
//}

.confirm-button, .cancel-button {
  margin: 16px 5px;
  min-width: 170px;
  font-size: 14px !important;
}