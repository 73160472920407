.button {
  min-width: 154px;
  border-radius: 5px;

  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;

  border: none;
  transition: all 0.3s;

  min-height: 38px;
  &:hover {
    transform: scale(1.02,1.02);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    transform: scale(1,1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    opacity: 0.6;
  }
  &:focus {
    outline:0;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

//.button {
//  border-radius: 5px;
//  font-weight: 500;
//  line-height: 17px;
//  border: none;
//  color: #FFFFFF;
//  transition: all 0.3s ease-in-out;
//  &:hover {
//    transform: scale(1.02,1.02);
//    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
//  }
//  &:disabled {
//    transform: scale(1,1);
//    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
//    opacity: 0.6;
//  }
//  &:focus {
//    outline:0;
//  }
//}

.button-img {
  margin-left: 6px;
  margin-right: 6px;
  filter: brightness(0) invert(1);
}

.primary {
  background-color: #226095;
}
//
//.secondary {
//  background: #8E8EA8;
//}
//
//.info {
//  background: #226095;
//}
//
.danger {
  background: #DE2525;
}

.outline {
  background: white;
  border: 1px solid #226095;
  color: #226095;
}