.wo-back {
  .MuiSwitch-colorSecondary, .MuiSwitch-colorSecondary.Mui-checked {
    &:hover {
      background-color: transparent;
    }
  }
}

.switch-container {
  position: relative;
}

.switch-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  padding-top: 1px;
  background-color: rgba(0,0,0,0.5);
}