.img-kolo-card {
  height: 137px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EAECF2;

  position: relative;
}

.img-kolo {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.img-kolo-text {
  color: #838383;
  font-size: 12px;
  letter-spacing: -0.07px;
  line-height: 18px;
  text-align: center;
}

.img-kolo-add {
  transition: 0.5s;
  background-color: rgba(111,111,111,0.1);
}

.img-kolo-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.ik-delete {
  height: 22px;
  width: 22px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(188,188,188,0.5);
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.ikc-not-disabled {
  cursor: copy;
  &:hover {
    border: 1px solid #838383;
    transform: scale(1.01,1.01);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  }
}

.img-kolo-loading{
  background-color: rgba(0,0,0,0.5);
  border-radius: 5px;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}