.pagination-kolo {
  .MuiPaginationItem-page {
    border-radius: 5px;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    &.Mui-selected {
      background-color: #226095;
      color: #FFFFFF;
      &:hover {
        background-color: #226095;
        opacity: 0.8;
      }
    }

    &:hover {
      background-color: #F2F2F2;
      .MuiSvgIcon-root {
        height: 21px;
        width: 21px;
        background-color: #F2F2F2;
        border-radius: 10px;
        fill: #1D1B42;
      }
    }
  }
  .MuiSvgIcon-root {
    height: 21px;
    width: 21px;
    background-color: #D2D2D2;
    border-radius: 10px;
    fill: #FFFFFF;
    transition: 0.5s;
  }
}