.select {
  box-sizing: border-box;
  height: 35px;
  width: 100%;
  border: 1px solid #E3E3E3;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #2F3542 !important;
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../../../assets/arrow-down-blue.svg');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 13px;
  &:focus {
    outline: 0;
  }
}

.select-title {
  color: #545459;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 2px;
}