.table-ib-button {
  background-color: #FFFFFF !important;
  height: 44px;
  min-width: 44px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  border-top: 1px solid #EAECF2;
  border-right: 1px solid #EAECF2;
  border-bottom: 1px solid #EAECF2;

  &:hover {
    background-color: #226095 !important;
    border: 1px solid #226095;
    .filter-img {
      filter: brightness(0) invert(1);
    }
  }

}

.table-ib-input {
  height: 44px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  flex: 1 1 auto;
  border: 1px solid #EAECF2;
}

.table-select {
  border: 1px solid #EAECF2;
  min-width: 250px;
  height: 44px !important;
  background-position-y: 18px;
}