.collapsible-text {
  color: #2F3542;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
}

.collapsible-text-blue {
  color: #226095;
}