.input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #E3E3E3;
  border-radius: 3px;
  background-color: #FFFFFF;
  letter-spacing: -0.09px;
  line-height: 18px;

  &:focus {
    outline: none;
    border: 1px solid #226095;
  }

  &::placeholder {
    color: #A7A7A7;
  }

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: Montserrat;
    font-size: 14px;
    padding: 8px;
  }
}

.title-input {
  color: #545459;
  font-size: 13px;
  letter-spacing: -0.31px;
  line-height: 22px;
  margin-bottom: 4px;
  //font-family: Montserrat;
}
.title-input-nm {
  color: #545459;
  font-size: 13px;
  letter-spacing: -0.31px;
  line-height: 22px;
}

.eye {
  position: absolute;
  right: 16px;
  top: 36px;

  &:hover {
    cursor: pointer;
    filter: invert(32%) sepia(8%) saturate(7336%) hue-rotate(175deg) brightness(60%) contrast(85%);
  }
}

.search-input {
  background-image: url(../../../../assets/icon-search.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 22px 22px;
  text-indent: 32px;
  &::placeholder {
    color: #A7A7A7;
    font-style: italic;
  }
}

.input-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.input-valid {
  color: green;
  font-size: 12px;
  margin-top: 4px;
}

.input-disabled {
  background-color: #FAFAFA;
}

.input-text-area {
  font-size: 14px;
  flex: 1 1 auto;
  width: 100%;
  padding: 8px 14px;
}
