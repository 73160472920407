.modal-close {
  padding-top: 3px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: scale(1.1, 1.1);
  }
  @media only screen and (min-width: 481px) {
    filter: brightness(0) invert(1);
  }
}

.confirm-button, .cancel-button {
  margin: 16px 5px;
  min-width: 170px;
  font-size: 14px !important;
}

.modal-mobile {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 50vh;
  max-height: 90vh;
  overflow-y: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
}