.images-container-horizontal {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  opacity: 1;
  transition: 0.5s;
}

.images-container-vertical {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 12px;
  margin-top: 16px;
  opacity: 1;
  transition: 0.5s;
}

.images-container-disabled {
  opacity: 0.5;
}

.img-kh {
  min-width: 220px;
  max-width: 220px;
  &:hover {
    transform: scale(1,1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }
}

.img-kh-left {
  margin-left: 19px;
}

.ig-scroll {
  overflow-x: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar-track
  {
    border-radius: 100px;
  }
  &::-webkit-scrollbar
  {
    width: 2px;
    height: 2px;
    border-radius: 100px;
    background-color: rgba(230, 230, 230, 0.2);
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: 100px;
    background-color: rgba(230, 230, 230, 0.8);
  }
}