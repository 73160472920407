@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800);

html *
{
  font-family: Montserrat;
}

h2 {
  color: #2F3542;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 24px;
}

h6 {
  color: #1D1B42;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
}

.title-normal {
  color: #2F3542;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  white-space: pre-line;
}

.text-normal {
  color: #2F3542;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.kolo-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 24px 40px;
  @media only screen and (max-width: 480px) {
    padding: 24px 20px;
  }
}

.titles-kolo {
  color: #1D1B42;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.text-small {
  color: #525252;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.text-blue {
  color: #226095;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.text-red {
  color: #DE2525;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.kolo-border-top {
  border-top: solid 1px #EAECF2;
}

.kolo-border-bottom {
  border-bottom: solid 1px #EAECF2;
}

.kolo-border-left {
  border-left: solid 1px #EAECF2;
}

.underline-hover {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.invert-color {
  filter: brightness(0) invert(1);
}

/* SIZES */
.lg {
  font-size: 16px;
  padding: 10px 18px;
}

.md {
  font-size: 14px;
  padding: 8px 14px;
}

.sm {
  font-size: 12px;
  padding: 6px 10px;
}

.a-in-opacity {
  animation-name: animationInOpacity;
}

.a-out-opacity {
  animation-name: animationOutOpacity;
}

.a-in-up {
  animation-name: animationInUp;
}

.a-in-down {
  animation-name: animationInDown;
}

.a-out-down {
  animation-name: animationOutDown;
}

.a-in-left {
  animation-name: animationInLeft;
}

.a-out-left {
  animation-name: animationOutLeft;
}

.a-in-right {
  animation-name: animationInRight;
}

.a-out-right {
  animation-name: animationOutRight;
}

.a-in-zoom {
  animation-name: animationInZoom;
}

.a-out-zoom {
  animation-name: animationOutZoom;
}

.a-in-bounce {
  animation-name: animationInBounce;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}

.a-wobble {
  animation-name: animationWobble;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.a-in-up, .a-in-down, .a-out-down, .a-in-left, .a-in-right, .a-out-left, .a-out-right {
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
}

.a-in-opacity, .a-out-opacity, .a-in-zoom, .a-out-zoom {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.a-very-fast {
  animation-duration: 0.3s;
}

.a-fast {
  animation-duration: 0.8s;
}

.a-normal {
  animation-duration: 1s;
}

.a-slow {
  animation-duration: 1.5s;
}

.a-very-slow {
  animation-duration: 2s;
}

@keyframes animationInUp {
  from {opacity: 0; transform: translateY(-300px)}
  to {opacity: 1; transform: translateY(0px)}
}

@keyframes animationInDown {
  from {opacity: 0; transform: translateY(300px)}
  to {opacity: 1; transform: translateY(0px)}
}

@keyframes animationOutDown {
  from {opacity: 1; transform: translateY(0px)}
  to {opacity: 0; transform: translateY(300px)}
}

@keyframes animationInLeft {
  from {opacity: 0; transform: translateX(-300px)}
  to {opacity: 1; transform: translateX(0px)}
}

@keyframes animationOutLeft {
  from {opacity: 1; transform: translateX(0px)}
  to {opacity: 0; transform: translateX(-300px)}
}

@keyframes animationInRight {
  from {opacity: 0; transform: translateX(300px)}
  to {opacity: 1; transform: translateX(0px)}
}

@keyframes animationOutRight {
  from {opacity: 1; transform: translateX(0px)}
  to {opacity: 0; transform: translateX(300px)}
}

@keyframes animationInOpacity {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes animationOutOpacity {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes animationInZoom {
  from {opacity: 0; transform:scale(0)}
  to {opacity: 1; transform:scale(1)}
}

@keyframes animationOutZoom {
  from {opacity: 1; transform:scale(1)}
  to {opacity: 0; transform:scale(0)}
}

@keyframes animationInBounce {
  0%{
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    transform: scale(1.1);
  }
  80%{
    transform: scale(0.89);
  }
  100%{
    transform: scale(1) translate3d(0,0,0);
  }
}

@keyframes animationWobble {
  12% {
    transform: rotate(15deg);
  }
  25% {
    transform: rotate(-30deg);
  }
  37% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
}