.quantity-picker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-quantity {
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;

  margin-left: 8px;
  margin-right: 8px;
  &:hover {
    transform: scale(1.04,1.04);
    box-shadow: 0 0 10px 4px rgba(256, 256, 256, 0.4);
  }
  @media only screen and (max-width: 768px) {
    margin: 6px;
  }
}

.product-quantity {
  min-width: 40px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: justify;
  justify-content: center;
}

.quantity-dark {
  .button-quantity {
    border: 2px solid #EEEEEE;
    &:hover {
      border: 2px solid #C4C4C4;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .product-quantity {
    color: #2F3542;
  }
}

.quantity-light {
  .button-quantity {
    border: 2px solid white;
  }
  .product-quantity {
    color: white;
  }
  .img-quantity{
    filter: brightness(0) invert(1);
  }
}

.button-big {
  width: 24px;
  height: 24px;
}

.quantity-big {
  color: #2F3542;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}