.card-white {
  border-radius: 5px;
  background-color: #FFFFFF;
  overflow: hidden;
}

.card-head {
  min-height: 46px;
  border-bottom: 1px solid #EAECF2;
  background-color: #226095;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ck-white {
  background-color: #FFFFFF;
}

.card-title {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.blue {
  color: #226095;
}

.ct-dark {
  color: #2F3542;
}

.ct-center {
  justify-content: center;
  color: #226095;
}

.tabs-container {
  flex: 1 1 auto;
  overflow: auto;

  &::-webkit-scrollbar-track
  {
    border-radius: 100px;
  }
  &::-webkit-scrollbar
  {
    width: 2px;
    height: 8px;
    border-radius: 100px;
    background-color: rgba(230, 230, 230, 0.2);
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: 100px;
    background-color: rgba(230, 230, 230, 0.8);
  }
}