.card-empty {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.empty-image {
  height: 40px;
  width: 40px;
  margin: 16px;
}

.empty-title {
  color: #2F3542;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 16px;
}

.empty-subtitle {
  color: #636363;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}