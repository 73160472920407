.badge-tab {
  background-color: #226095;
  color: white;
  border-radius: 20px;
  min-height: 24px;
  min-width: 24px;

  margin-left: 8px;
  margin-right: 8px;

  font-size: 12px;
  font-weight: bold;

  justify-content: center;
  align-items: center;

  display: flex;
}

.tabs-img {
  height: 24px;
  width: 24px;
  object-fit: contain;
  margin-left: 8px;
  margin-right: 8px;
}