.dp-border {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: #E3E3E3 !important;
    }
  }
  .MuiSvgIcon-root {
    fill: rgba(34, 96, 150, 0.7);
  }
}

.dp-title {
  color: #545459;
  font-size: 13px;
  letter-spacing: -0.31px;
  line-height: 22px;
}

